@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap);
/*body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #F8F8F8 !important;
}
*/

#root {
  height: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  font-family: 'Rubik', Arial, sans-serif;
  /*background-color: #F8F8F8;*/
  background: rgb(20,67,115);
  background: -webkit-gradient(linear, left top, right top, from(rgba(8,70,115,1)), to(rgba(17,119,211,1)));
  background: -webkit-linear-gradient(left, rgba(8,70,115,1) 0%, rgba(17,119,211,1) 100%);
  background: linear-gradient(90deg, rgba(8,70,115,1) 0%, rgba(17,119,211,1) 100%);
}

* {
  box-sizing: border-box;
}

button {
  font-size: 1rem;
  font-family: 'Rubik', Arial, sans-serif;
}

body strong {
  font-weight: 500;
}

body a {
  color: #206dc9;
  text-decoration: none;
  font-weight: 500;
}

body a:hover {
  color: #195693;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

